/** @jsx jsx */
import { arrayOf, oneOf, string } from 'prop-types'
import { Container, Heading, jsx, Text } from 'theme-ui'
import { getImage } from 'gatsby-plugin-image'

import ButtonList from '~/components/ButtonList'
import IntroTransition from '~/components/IntroTransition'
import Section from '~/components/Section'
import renderLink from '~/content/renderLink'
import ContentLeftLayout from '~/layout/ContentLeftLayout'
import {
  ContentfulImagePropType,
  ContentfulImageType,
  ContentfulRichTextPropType,
  ContentfulRichTextType,
  LinkPropType,
  LinkType,
} from '~/types'
import renderRichText from '~/content/renderRichText'

const DISPLAY_NAME = 'GenericHeroSection'

const PROP_TYPES = {
  backgroundImage: ContentfulImagePropType,
  body: ContentfulRichTextPropType,
  elementId: string,
  heading: string.isRequired,
  kicker: string,
  links: arrayOf(LinkPropType),
  textColorScheme: oneOf(['Light', 'Dark']),
}

interface PropTypes {
  backgroundImage: ContentfulImageType,
  body: ContentfulRichTextType,
  elementId?: string,
  heading: string,
  kicker: string,
  links: LinkType[],
  textColorScheme: 'Light' | 'Dark',
}

const Component = ({
  backgroundImage,
  body,
  elementId,
  heading,
  kicker,
  links,
  textColorScheme = 'Light',
}: PropTypes) => (
  <Section
    backgroundImage={getImage(backgroundImage.localFile)}
    belowHeader
    id={elementId}
    textColorScheme={textColorScheme}
  >
    <Container>
      <IntroTransition>
        <ContentLeftLayout>
          <header>
            {kicker && (
              <Text variant="bodyUppercase">
                {kicker}
              </Text>
            )}
            <Heading
              as="h1"
              variant="headingHero"
            >
              {heading}
            </Heading>
            {body && (
              <Text
                as="div"
                sx={{
                  variant: 'text.prospectBody',
                  marginTop: 1,
                }}
              >
                {renderRichText(body)}
              </Text>
            )}
            {links && (
              <ButtonList
                sx={{
                  justifyContent: 'flex-start',
                  marginTop: 2,
                }}
              >
                {links.map(({ id, ...link }) => (
                  <li key={id}>
                    {renderLink(link, { scheme: textColorScheme })}
                  </li>
                ))}
              </ButtonList>
            )}
          </header>
        </ContentLeftLayout>
      </IntroTransition>
    </Container>
  </Section>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
